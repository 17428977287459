<template>
    <placeholder-panel>
        <div>
            <h1 class="display-2 text-center">
                👈
            </h1>
            <p>
                Select a <strong>Promotion</strong> for details
            </p>
        </div>
    </placeholder-panel>
</template>

<script>
export default {
    name: "PromotionPlaceholder",
    components: {
        PlaceholderPanel: () => import("../PlaceholderPanel"),
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>

</style>
